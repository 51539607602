import './cookie-prompt/bbccookies.scss';

/*Add from cookie library*/
var onHttps = document.location.protocol === 'https:';

const IMPLICIT_FLAG = '0';
const EXPLICIT_FLAG = '1';
const EXPLICIT_FLAG_EU = '2';
const POLICY_REFRESH_DATE_MILLIS = new Date(2015, 4, 21, 0, 0, 0, 0).getTime();
const PRIVACY_COOKIE_NAME = 'ckns_privacy';
const EXPLICIT_COOKIE_NAME = 'ckns_explicit';
const POLICY_COOKIE_NAME = 'ckns_policy';
const CURRENT_PRIVACY_POLICY = 'july2019';
const POLICY_EXPIRY_COOKIENAME = 'ckns_policy_exp';

var GDPR_COUNTRIES = ['AT', 'AX', 'BE', 'BG', 'BL', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'EU', 'FI', 'FR', 'GB', 'GF', 'GG', 'GI', 'GP', 'GR', 'HR', 'HU', 'IE', 'IM', 'IS', 'IT', 'JE', 'LI', 'LT', 'LU', 'LV', 'MF', 'MT', 'MQ', 'NL', 'NO', 'PL', 'PM', 'PT', 'RE', 'RO', 'SE', 'SI', 'SK', 'YT'];

const cookieOvenEndpointMap = {
  'www.int.bbc.co.uk':
    'https://www.test.bbc.co.uk/cookieoven',
  'www.int.bbc.com':
    'https://www.int.bbc.com/cookieoven',
  'www.test.bbc.co.uk':
    'https://www.test.bbc.co.uk/cookieoven',
  'www.test.bbc.com':
    'https://www.test.bbc.com/cookieoven',
  'navigation.int.api.bbc.co.uk':
    'https://www.test.bbc.co.uk/cookieoven',
  'navigation.test.api.bbc.co.uk':
    'https://www.test.bbc.co.uk/cookieoven',
  'navigation.int.api.bbc.com':
    'https://www.test.bbc.com/cookieoven',
  'navigation.test.api.bbc.com':
    'https://www.test.bbc.com/cookieoven',
  'cookies.test.api.bbc.co.uk':
    'https://www.test.bbc.co.uk/cookieoven',
    /* //commented this out so webpack strips it out, bring it back in to change this behaviour
  'local.dev.bbc.co.uk:8080':   //cookies webmodule locally
    'http://local.dev.bbc.co.uk:8080/cookieoven',
  'local.dev.bbc.co.uk:7443':   //cookies webmodule locally over HTTPS
    'https://local.dev.bbc.co.uk:7443/cookieoven',
  'local.dev.bbc.co.uk:5000':   //Orbit locally
    'https://www.test.bbc.co.uk/cookieoven',
  'local.dev.bbc.com:8080':   //cookies webmodule locally
    'http://local.dev.bbc.com:8080/cookieoven',
  'local.dev.bbc.com:7443':   //cookies webmodule locally over HTTPS
    'https://local.dev.bbc.com:7443/cookieoven',
  'local.dev.bbc.com:5000':   //Orbit locally
    'https://www.test.bbc.com/cookieoven',
  */
};


require(['jsonp'], function (jsonp) {

  var COOKIE_PROMPT_CLASS_NAMES = '';
  var COOKIE_PROMPT_CLASS_NAMES_FULL_SCREEN_OVERLAY = 'full-screen full-screen-bottom';
  var COOKIE_PROMPT_CLASS_NAMES_FULL_SCREEN = 'full-screen';

  // some pages require that we do not add the overlay to the fullscreen cookie banner,
  // because the user needs to interact with the page before accepting cookies
  // (e.g. the cookie settings page)
  // the page name is checked against this list

  var PAGES_WITHOUT_FULLSCREEN_BANNER_OVERLAY = ['usingthebbc.'];

  var prevPrivacyCookieValue;

  function setCookie(name, value, options) {
    var nextYear = new Date();
    nextYear.setYear(nextYear.getFullYear() + 1);

    if (!options) {
      options = {
        domain: getCurrentBBCDomain(),
        path: '/',
        expires: nextYear,
        sameSite: 'None',
        secure: onHttps
      }
    }

    var cookieString = generateCookieString(name, value, options);

    document.cookie = cookieString;
  };

  function getCurrentBBCDomain() {
    try {
      var domain = window.location.hostname.toLowerCase();
      return domain.match(/.*(bbc\.co\.uk|bbc\.com)\/*/)[1];
    } catch (err) {
      return undefined;
    }
  };

  function generateCookieString(key, value, options) {
    var theDomain = (options && options.domain) ? ';domain=' + options.domain : '';
    value = (value + '').replace(/[^!#$&-+\--:<-\[\]-~]/g, encodeURIComponent);
    options = options || {};

    var cookieString = key + '=' + value;
    cookieString += options.path ? ';path=' + options.path : '';
    cookieString += options.domain ? ';domain=' + options.domain : '';
    cookieString += options.expires ? ';expires=' + options.expires.toUTCString() : '';
    cookieString += ';sameSite=' + options.sameSite || 'None';
    cookieString += options.secure || onHttps ? ';secure' : '';
    return cookieString;
  }

  function getCookie(key) {
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  };

  function policyIsExplicit(region) {
    var policy = getCookie(EXPLICIT_COOKIE_NAME);
    if (policy === null) {
      return false;
    }
    if (region === 'gb' || region === 'row') {
      return policy >= EXPLICIT_FLAG;
    }
    if (region === 'eu') {
      return policy === EXPLICIT_FLAG_EU;
    }
    throw new Error('region not recognised: ' + region);
  }

  function showPrivacyBanner(container, privacyPromptElement, cookiePromptElement) {
    privacyPromptElement.style.display = 'inline-block';
    privacyPromptElement.style.width = '100%';
    cookiePromptElement.style.display = 'none';
    container.className = COOKIE_PROMPT_CLASS_NAMES;
  }

  function hideAllBanners(container, privacyPromptElement, cookiePromptElement) {
    privacyPromptElement.style.display = 'none';
    cookiePromptElement.style.display = 'none';
    container.className = COOKIE_PROMPT_CLASS_NAMES;
  }

  // This returns appropriate urlPrefix based on whether the page belongs to ORB or Orbit.
  function getUrlPrefix() {
    var urlPrefix = 'https://www.bbc.com';
    var matches = window.location.host.match(/(bbc(?:\.co\.uk))(:\d+)?(\/.*)?$/i);
    if (matches && matches[1] === 'bbc.co.uk') {
      urlPrefix = urlPrefix.replace('bbc.com', 'bbc.co.uk');
    }
    return urlPrefix;
  }

  function showCookieBannerIfPolicyNotExplicit(container, privacyPrompt, cookiePrompt, region, pageName) {
    if (policyIsExplicit(region)) {
      return;
    }

    function getClassName(pageName) {
      var pages = PAGES_WITHOUT_FULLSCREEN_BANNER_OVERLAY;
      for (var i=0; i<pages.length; i++) {
        if (pageName.search(pages[i]) > -1) {
          return COOKIE_PROMPT_CLASS_NAMES_FULL_SCREEN;
        }
      }
      return COOKIE_PROMPT_CLASS_NAMES_FULL_SCREEN_OVERLAY;
    }

    var fullScreen = region === 'eu';
    var fullScreenClassName = getClassName(pageName);

    privacyPrompt.style.display = 'none';
    cookiePrompt.style.display = 'inline-block';
    cookiePrompt.style.width = '100%';
    container.className = fullScreen ? fullScreenClassName : COOKIE_PROMPT_CLASS_NAMES;
  }

  function initialiseBanners(region, pageName) {
    var container = document.getElementById('cookiePrompt');
    var privacyPrompt = document.getElementById('bbcprivacy');
    var cookiePrompt = document.getElementById('bbccookies');
    var privacyContinueButton = document.getElementById('bbcprivacy-continue-button');

    privacyContinueButton.addEventListener('click', function () {
      hideAllBanners(container, privacyPrompt, cookiePrompt);
      showCookieBannerIfPolicyNotExplicit(container, privacyPrompt, cookiePrompt, region, pageName);
    });

    var cookieContinueButton = document.getElementById('bbccookies-continue-button');

    cookieContinueButton.addEventListener('click', function () {
      hideAllBanners(container, privacyPrompt, cookiePrompt);
      const fullOptIn = {
        ads: true,
        personalisation: true,
        performance: true,
        necessary: true,
      };
      setPolicy(fullOptIn);
    });

    var cookieSettingsLink = document.getElementById('bbccookies-settings')
      .getElementsByTagName('a')[0];

    cookieSettingsLink.addEventListener('click', (function () {
      let clicked = false;

      return function (event) {
        if (!clicked) {
          //suppress the initial navigation
          event.preventDefault();
          const defaultPolicy = {
            ads: false,
            personalisation: false,
            performance: false,
            necessary: true,
          };
          setPolicy(defaultPolicy).then(function () {
            clicked = true;
            //'re-click' the link to trigger the suppressed navigation
            cookieSettingsLink.click();
          });
        }
      };
    })());
    showCookieBannerIfPolicyNotExplicit(container, privacyPrompt, cookiePrompt, region, pageName);

    var shouldSeePrivacyBanner = false;

    // if cookie exists and value is different to current value then show banner
    if (prevPrivacyCookieValue !== undefined &&
        prevPrivacyCookieValue !== null &&
        prevPrivacyCookieValue !== CURRENT_PRIVACY_POLICY) {
          shouldSeePrivacyBanner = true;
    }
    if (shouldSeePrivacyBanner) {
      showPrivacyBanner(container, privacyPrompt, cookiePrompt);
    } else {
      showCookieBannerIfPolicyNotExplicit(container, privacyPrompt, cookiePrompt, region, pageName);
    }
  }

  function setPolicy(policy){

    const policyString = '?policy=' + (policy.ads ? 1 : 0) + (policy.personalisation ? 1 : 0) + (policy.performance ? 1 : 0);
    const serversideCookieURL = getServersideCookieURL(window.location.host);

    //this domain
    fetch(`${serversideCookieURL}${policyString}`, { credentials: 'include' })

    // other domain
    let otherDomainServersideCookieURL
    let currentDomain = getCurrentBBCDomain()
      .match(/(bbc.co.uk|bbc.com)/)[0]
    if (currentDomain === "bbc.co.uk") {
      otherDomainServersideCookieURL = serversideCookieURL.replace(".bbc.co.uk", ".bbc.com")
    } else {
      otherDomainServersideCookieURL = serversideCookieURL.replace(".bbc.com", ".bbc.co.uk")
    }
    return fetch(`${otherDomainServersideCookieURL}${policyString}`, { credentials: 'include' })
  }

  function getServersideCookieURL(host) {
    return cookieOvenEndpointMap[host] || `https://www.${getCurrentBBCDomain()}/cookieoven`;
  }

  function getSanitisedLanguage(locale) {
    var lang = 'en';

    if (typeof(locale) === 'string' && locale.length >= 2) {
      lang = locale.trim().substring(0, 2).toLowerCase();
    }

    var localeIsSupported = ['en', 'cy', 'gd', 'ga'].indexOf(lang) !== -1;

    if (!localeIsSupported) {
      return 'en';
    }
    return lang;
  }

  function loadContentOfBanners(locale, initialiseBanners, region, pageName) {
    var lang = getSanitisedLanguage(locale);

    var CSS_URL = window.COOKIES_STATIC_HOST + 'bbccookies.min.css';
    var url = window.COOKIES_STATIC_HOST + lang + '.min.js';

    window.bbcpage.loadCSS(CSS_URL).then(function () {
      jsonp(
        url,
        { name: 'cookiePrompt' },
        function (err, template) {
          var cookiePrompt = document.createElement('div');
          cookiePrompt.id = "cookiePrompt";
          document.body.prepend(cookiePrompt);
          var parsedHTML = template.replace(/<<bbcUrlPrefix>>/g, getUrlPrefix());
          cookiePrompt.innerHTML = parsedHTML;

          var elements = document.querySelectorAll('[data-region-filter]');
          for (var i=0; i<elements.length; i++) {
            var el = elements[i];
            if (el.getAttribute('data-region-filter') !== region) {
              el.style.display = 'none';
            }
          }
          initialiseBanners(region, pageName);
        }
      );
    }.bind(this));
  }

  function nextYear() {
    var d = new Date();
    d.setYear(d.getFullYear() + 1);
    return d;
  }

  //**Feels like duplicate of setPolicy, but copying as is for now**
  function writePolicy(policy, isCrossDomainEnabled, done) {
    var d = nextYear();
    var policyString = '' + (policy.ads ? 1 : 0) + (policy.personalisation ? 1 : 0) + (policy.performance ? 1 : 0);

    var currentDomain = getCurrentBBCDomain();
    setCookie(POLICY_COOKIE_NAME, policyString, {
      domain: currentDomain,
      path: '/',
      expires: d,
      crossDomainEnabled: isCrossDomainEnabled,
      sameSite: 'None',
      secure: onHttps
    }, done);

    // expire this cookie one month after policy cookie
    var expires = new Date(d.getTime());
    expires.setMonth(expires.getMonth() + 1);

    setCookie(POLICY_EXPIRY_COOKIENAME, d.getTime(), {
      domain: currentDomain,
      path: '/',
      expires: expires,
      sameSite: 'None',
      secure: onHttps
    });

    return policy;
  }

  function setImplicitPolicy(region) {
    var ukDefaultPolicy = {
      ads: false,
      personalisation: false,
      performance: false,
      necessary: true
    };
    var euDefaultPolicy = {
      ads: false,
      personalisation: false,
      performance: false,
      necessary: true
    };
    var restOfTheWorldDefaultPolicy = {
      ads: true,
      personalisation: true,
      performance: true,
      necessary: true
    };

    if (region === 'gb') {
      writePolicy(ukDefaultPolicy);
    } else if (region === 'eu') {
      writePolicy(euDefaultPolicy);
    } else if(region === 'row') {
      writePolicy(restOfTheWorldDefaultPolicy);
    }
  };

  Promise.all([
    window.bbcuser.isUKCombined(),
    window.bbcuser.getCountry(),
    window.bbcpage.getLanguage(),
    window.bbcpage.getName()
  ]).then(function (conditions) {

    var isUK = conditions[0];
    var countryCode = conditions[1];
    var language = conditions[2];
    var pageName = conditions[3];

    var isEU = GDPR_COUNTRIES.indexOf(countryCode.toUpperCase()) !== -1;

    var region;

    if (isUK) {
      region = 'gb';
    } else if (isEU) {
      region = 'eu';
    } else {
      region = 'row';
    }

    if (!policyIsExplicit(region)) {
      setCookie(EXPLICIT_COOKIE_NAME, IMPLICIT_FLAG);
      setImplicitPolicy(region);
    }

    if (region === 'gb' || region === 'eu') {
      var seenPrivacyBanner = (getCookie(PRIVACY_COOKIE_NAME) === CURRENT_PRIVACY_POLICY);

      prevPrivacyCookieValue = getCookie(PRIVACY_COOKIE_NAME);
      if (!seenPrivacyBanner || !policyIsExplicit(region)) {
        loadContentOfBanners(language, initialiseBanners, region, pageName);
      }
      setCookie(PRIVACY_COOKIE_NAME, CURRENT_PRIVACY_POLICY);
    }
  }).catch(function () {
    loadContentOfBanners('en', initialiseBanners, 'gb', '');
  });
});
